body,
html {
  height: 100vh;
}

/* IOS safari fix to prevent "jumping around" issues when using overflow: hidden */
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
}

#root {
  position: relative;
}